<template>
  <div class="steps-nav">
    <div class="steps-nav__left">
      <app-button color="grey" size="large" @click="prev">
        {{ prevStepTexts[currentStep] }}
        <template #icon>
          <inline-svg :src="require('@/assets/img/icons/deep-settings/nav-prev.svg')" />
        </template>
      </app-button>
      <template v-if="!isShowSaveButtons">
        <app-button
          content="Необходимо заполнить все обязательные поля"
          color="red"
          size="large"
          v-if="isNextStepDisabled"
          v-tippy="{
            maxWidth: 200
          }"
          key="plug"
          is-reverse
        >
          {{ nextStepTexts[currentStep] }}
          <template #icon>
            <img src="@/assets/img/icons/deep-settings/nav-next.svg" alt="→" />
          </template>
        </app-button>
        <app-button @click="next" color="red" size="large" is-reverse v-else>
          {{ nextStepTexts[currentStep] }}
          <template #icon>
            <img src="@/assets/img/icons/deep-settings/nav-next.svg" alt="→" />
          </template>
        </app-button>
      </template>
    </div>
    <div class="steps-nav__right" v-if="isShowSaveButtons">
      <slot v-if="$slots.default" />
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import { steps } from '@/utils/deepSettings'
import AppButton from '@/components/elements/AppButton'

const nextStepTexts = {
  [steps.commonData]: 'Точность расчета',
  [steps.calculationAccuracy]: 'Блок стоимости'
}

const prevStepTexts = {
  [steps.commonData]: 'Расчет',
  [steps.calculationAccuracy]: 'Общие данные',
  [steps.calculationPrice]: 'Точность расчета'
}

export default {
  components: { AppButton, InlineSvg },
  props: {
    currentStep: {
      type: String,
      required: true,
      validator: v => Object.keys(steps).includes(v)
    },
    isNextStepDisabled: {
      type: Boolean
    }
  },
  name: 'StepsNav',
  methods: {
    prev() {
      this.$emit('prev')
    },
    next() {
      this.$emit('next')
    }
  },
  computed: {
    isShowSaveButtons() {
      return this.currentStep === steps.calculationPrice
    },
    nextStepTexts: () => nextStepTexts,
    prevStepTexts: () => prevStepTexts
  }
}
</script>

<style scoped lang="sass">
.steps-nav
  display: flex
  align-items: center
  justify-content: space-between
  &__left, &__right
    display: flex
    align-items: center
    gap: rem(8)
</style>
